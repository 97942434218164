const isProd = process.env.NODE_ENV !== "development";

module.exports = {
  // Your website's name, used for favicon meta tags
  site_name: "Website",

  // Your website's description, used for favicon meta tags
  site_description: "Website description",

  // Your website url
  site_url: isProd
    ? "https://hemofarm-onboarding.com/"
    : "http://localhost:3000",

  // Theme color
  theme_color: "#3ca082",

  // Api base url
  api_base_url: "",
};
