export default class LazyLoad {
  constructor() {
    this.items = [];
    this.breakpoints = [
      {
        width: 567,
        src: "lazy-xs",
      },
      {
        width: 768,
        src: "lazy-sm",
      },
      {
        width: 992,
        src: "lazy-md",
      },
      {
        width: 1200,
        src: "lazy-lg",
      },
    ];
  }

  init() {
    this.items = $("[data-lazy]");

    this.initLazy();
  }

  initLazy() {
    let self = this;

    let options = {
      rootMargin: "200px",
      threshold: 0,
    };

    let observer = new IntersectionObserver(observerCallback, options);

    this.items.each(function (i, item) {
      let type = item.localName;
      if (
        !$(item).find(".bg").length &&
        (type === "div" || type === "section")
      ) {
        $(item).append("<span class='bg'></span>");
      }
      observer.observe(item);
    });

    function observerCallback(entries, observer) {
      entries.forEach((entry, index) => {
        if (entry.isIntersecting) {
          let $el = $(entry.target);
          let src = self.getSrc($el, window.innerWidth);
          self.setSrc($el, src);
          observer.unobserve($el[0]);
        }
      });
    }
  }

  getSrc($el, winWidth) {
    let src = $el.data("lazy");
    for (let i = 0; i < this.breakpoints.length; i++) {
      let breakpoint = this.breakpoints[i];
      if (winWidth < breakpoint.width && $el.data(breakpoint.src)) {
        src = $el.data(breakpoint.src);
        break;
      }
    }
    return src;
  }

  setSrc($el, src) {
    if (src) {
      let type = $el[0].localName;

      if (type !== "div" && type !== "section") {
        $el[0].src = src;
      } else {
        $el.find(".bg").css("background-image", "url(" + src + ")");
      }

      $el.addClass("lazy-loaded");
    } else {
      $el.addClass("lazy-error");
    }
  }

  update(winWidth) {
    let self = this;

    this.items.each(function (i, item) {
      let $el = $(item);
      let src = self.getSrc($el, winWidth);
      self.setSrc($el, src);
    });
  }
}
