import { baseURLLocal, baseURLProduction, baseURLStaging } from "../../../global-constants";
import { callApi, goToScreen } from "../../../partials/pages/home";
import App from "./App";
import device from "current-device";

export default class Quotes {
  constructor() {
    this.app = new App();
    this.app.init();
    this.baseUrl = baseURLProduction;
    this.introScreenQuiz = document.querySelector(".js-intro-quiz");
    this.avatarShowBtn = document.querySelector(".js-avatar-show");
  }

  init() {
    const nextButtons = document.querySelectorAll(".js-next-btn");
    const startQuizBtn = document.querySelector(".js-start-quiz");
    const btnBackDoors = document.querySelectorAll(".js-back-doors");
    let _this = this;

    function getParents(element, selector) {
      var parents = [];
      var currentElement = element.parentElement;

      while (currentElement !== null) {
        if (selector) {
          if (currentElement.matches(selector)) {
            parents.push(currentElement);
          }
        } else {
          parents.push(currentElement);
        }

        currentElement = currentElement.parentElement;
      }

      return parents;
    }

    nextButtons.forEach((button) => {
      button.addEventListener("click", (e) => {
        let screens = getParents(e.currentTarget, ".js-introduction-screen");
        let avatarImg = document.querySelectorAll(".js-avatar-placeholder");
        let parents = getParents(e.currentTarget, ".avatarImg");

        screens.forEach((screen) => {
          if (screen.classList.contains("active")) {
            let nextScreen = screen.nextElementSibling;
            screen.classList.remove("active");
            nextScreen.classList.add("active");

            if (nextScreen.classList.contains("qoutes--reverse")) {
              nextScreen.classList.toggle("qoutes--speak");

              avatarImg.forEach((item) => {
                item.classList.add("deactive");
              });
            } else {
              avatarImg.forEach((item) => {
                item.classList.remove("deactive");
              });
            }
          }
        });

        if (e.currentTarget.dataset.bg != undefined) {
          let bg = e.currentTarget.dataset.bg;
          let parents = getParents(e.currentTarget, ".screen-bg");

          parents.forEach((parent) => {
            let parentBgImg = parent.querySelector(".bg");
            parentBgImg.style.backgroundImage = `url(${bg})`;
          });
        }
        if (window.innerWidth < 768) {
          if (e.currentTarget.dataset.bgxs != undefined) {
            let bg = e.currentTarget.dataset.bgxs;
            let parents = getParents(e.currentTarget, ".screen-bg");

            parents.forEach((parent) => {
              let parentBgImg = parent.querySelector(".bg");
              parentBgImg.style.backgroundImage = `url(${bg})`;
            });
          }
        }
      });
    });

    startQuizBtn.addEventListener("click", (e) => {
      this.app.startTimer(30);
      let quotesLast = e.currentTarget.parentNode;
      quotesLast.style.display = "none";
      this.introScreenQuiz.classList.add("is-active");

      // let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
      // if(JSON.parse())

      if (JSON.parse(localStorage.getItem("Intro")).progress === "completed") {
        // this.app.init();

        this.app.showResult();
        return;
      }

      // this.app.init();
      this.app.getQuestions(1);
      return;
    });

    let levelTrigger = document.querySelectorAll(".js-level");
    let levelGameTrigger = document.querySelectorAll(".js-level-game");
    let hallStepsParent = document.querySelector(".js-hall-steps");

    let activeLevel = null;

    levelTrigger.forEach((item) => {
      let levels = {
        1: "Intro",
        2: "Level 1",
        3: "Level 2",
        4: "Level 3",
        5: "Level 4",
        6: "Level 5",
      };

      item.addEventListener("click", (e) => {
        // document.querySelectorAll(".js-qoutes-exit-screen").forEach(item => {
        //   item.style.display = 'none';
        // });

        // document.querySelector(`.js-level-${e.target.dataset.level} .js-qoutes-exit-screen`).style.display = 'none';
        // document.querySelector(`.js-level-${e.target.dataset.level} .js-introduction-screen`).style.display = 'block';
        let images = e.currentTarget.querySelectorAll("img");
        let itemLevel = e.target.dataset.level;
        localStorage.setItem(`data-level-${itemLevel}`, itemLevel);
        // let currentQuestionForLevel = JSON.parse(localStorage.getItem(levels[itemLevel])).question;

        callApi(
          `${this.baseUrl}/get-progress-by-level-name?level_name=${levels[itemLevel]}`,
          "GET",
          {},
          localStorage.getItem("access_token")
        ).then((response) =>
          localStorage.setItem("currentQuestion", response.question)
        );
        // if (response) {

        // } else {
        //   localStorage.setItem('currentQuestion', 1);
        // }

        hallStepsParent.classList.add("hide");
        // Do something with the images, for example, add a "selected" class
        images.forEach((image) => {
          if (images.length >= 2) {
            images[1].classList.add("selected");
          }
        });

        let levelID = e.currentTarget.dataset.level;
        let levelElement = document.querySelector(`.js-level-${levelID}`);

        if (activeLevel) {
          activeLevel.classList.remove("is-active");
        }

        levelElement.classList.add("is-active");
        activeLevel = levelElement;
        let quizCompleted = localStorage.getItem(`quizCompleted_${levelID}`);

        if (quizCompleted) {
          levelElement.classList.add("completed");
        }
        if (window.innerWidth < 768) {
          if (levelElement.classList.contains("is-active")) {
            document.querySelectorAll(".avatar-placeholder").forEach((item) => {
              item.classList.add("active");
            });
          }
        }
        let avatarImg = document.querySelectorAll(".js-avatar-placeholder");
        avatarImg.forEach((item) => {
          item.classList.remove("deactive");
        });
      });
    });
    const keys = Object.keys(localStorage);
    keys.forEach((key) => {
      if (key.startsWith("data-level")) {
        let value = localStorage.getItem(key);
        let dataSelectedValue = document.querySelector(
          `[data-level='${value}']`
        );
        dataSelectedValue.querySelectorAll("img")[1].classList.add("selected");
      }
    });

    levelGameTrigger.forEach((trigger) => {
      trigger.addEventListener("click", (e) => {
        let parentEl = trigger.parentNode.parentNode;
        parentEl.classList.remove("active");
        this.introScreenQuiz.classList.add("is-active");
        let target = e.currentTarget;
        let catID = Number(target.dataset.level) + 1;
        localStorage.setItem("currentDoorId", catID);
        let catIdNum = parseInt(catID);
        const getQuestionID = async (catNum) => {
          let result = await _this.app.getQuestions(
            catNum,
            localStorage.getItem("currentQuestion")
          );
        };
        getQuestionID(catIdNum);
      });
    });

    btnBackDoors.forEach((trigger) => {
      trigger.addEventListener("click", () => {
        let parentEl = trigger.parentNode.parentNode.parentNode.parentNode;
        document
          .querySelector(".hall-level.is-active")
          .classList.remove("is-active");
        if (hallStepsParent.classList.contains("hide")) {
          hallStepsParent.classList.remove("hide");
        } else {
          hallStepsParent.classList.add("hide");
        }
        if (parentEl.classList.contains("is-active")) {
          hallStepsParent.classList.remove("is-active");
        }
        if (window.innerWidth < 768) {
          let finishQoutes = document.querySelector(".js-finish-qoutes");
          if (finishQoutes.classList.contains("deactive")) {
            finishQoutes.classList.remove("deactive");
            finishQoutes.classList.add("avatar-placeholder__image--reverse");
          }
        }
      });
    });

    this.avatarShowBtn.addEventListener("click", () => {
      let avatarPlaceholder = document.querySelectorAll(".avatar-placeholder");
      avatarPlaceholder[1].classList.add("active");
      goToScreen("question-intro");
    });

    function removeScreenMobile() {
      let removeScreenMobile = document.querySelectorAll(".js-qoutes-mob");
      removeScreenMobile.forEach((item) => {
        item.remove();
      });
    }
    if (window.innerWidth < 768) {
      removeScreenMobile();
    }

    const device = require("current-device").default;

    function checkDevice() {
      if (device.tablet() || device.ipad()) {
        let tablet = document.querySelectorAll(".js-tablet");
        tablet.forEach((item) => {
          item.classList.add("is-active");
        });
      }
    }
    checkDevice();

    window.addEventListener("orientationchange", () => {
      setTimeout(() => {
        checkDevice();
      });
    });
  }
}
