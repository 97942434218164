import "regenerator-runtime/runtime"

if ('serviceWorker' in navigator && process.env.NODE_ENV !== 'development') {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('service-worker.js').then(registration => {
      console.log('SW registered!')
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError)
    })
  })
}