import "./robots.txt";
import "./assets/styles/main.scss";
import "./assets/js/vendor";

import Cookies from "js-cookie";
import siteConfig from "../config/site.config";
import Avatar from "./assets/js/comp/Avatar";
import Popup from "./assets/js/comp/Popup";
import Quotes from "./assets/js/comp/Quotes";

import LazyLoad from "./assets/js/comp/LazyLoad";

window.cookie = Cookies;
window.siteConfig = siteConfig;

/**
 * Components
 */

/**
 * Check if document is ready cross-browser
 * @param callback
 */
const ready = (callback) => {
  if (document.readyState !== "loading") {
    /**
     * Document is already ready, call the callback directly
     */
    callback();
  } else if (document.addEventListener) {
    /**
     * All modern browsers to register DOMContentLoaded
     */
    document.addEventListener("DOMContentLoaded", callback);
  } else {
    /**
     * Old IE browsers
     */
    document.attachEvent("onreadystatechange", function () {
      if (document.readyState === "complete") {
        callback();
      }
    });
  }
};

/**
 * Document ready callback
 */
ready(() => {
  /**
   * Touchstart iOS fix
   */
  document.addEventListener("touchstart", () => {}, true);
  /**
   * Avatar component
   * @type {Avatar}
   */
  let avatar = new Avatar();
  avatar.init();

  /**
   * Popup component
   * @type {Popup}
   */
  let popup = new Popup();
  popup.init();

  /**
   * Quotes component
   * @type {Quotes}
   */
  let quotes = new Quotes();
  quotes.init();

  /**
   * LazyLoad component
   * @type {LazyLoad}
   */
  let lazy = new LazyLoad();
  lazy.init();
});
