import "./main.scss";
//Mam & Women DEF//
import "assets/img/Avatar/def-avatar-female.png";
import "assets/img/Avatar/def-avatar-male.png";

//////////////////////////////////////////////////////////////////
//Man Skin//
import "assets/img/Avatar/Male/Skin/skin-man-asian.png";
import "assets/img/Avatar/Male/Skin/skin-man-dark.png";
import "assets/img/Avatar/Male/Skin/skin-man-latin.png";
import "assets/img/Avatar/Male/Skin/skin-man-caucasian.png";

//Female Skin//
import "assets/img/Avatar/Female/Skin/skin-woman-asian.png";
import "assets/img/Avatar/Female/Skin/skin-woman-dark.png";
import "assets/img/Avatar/Female/Skin/skin-woman-latin.png";
import "assets/img/Avatar/Female/Skin/skin-woman-caucasian.png";

//////////////////////////////////////////////////////////////////
//Man Hair//
import "assets/img/Avatar/Male/Hair/hair-style-1-color-1.png";
import "assets/img/Avatar/Male/Hair/hair-style-1-color-2.png";
import "assets/img/Avatar/Male/Hair/hair-style-1-color-3.png";
import "assets/img/Avatar/Male/Hair/hair-style-1-color-4.png";
import "assets/img/Avatar/Male/Hair/hair-style-2-color-1.png";
import "assets/img/Avatar/Male/Hair/hair-style-2-color-2.png";
import "assets/img/Avatar/Male/Hair/hair-style-2-color-3.png";
import "assets/img/Avatar/Male/Hair/hair-style-2-color-4.png";
import "assets/img/Avatar/Male/Hair/hair-style-3-color-1.png";
import "assets/img/Avatar/Male/Hair/hair-style-3-color-2.png";
import "assets/img/Avatar/Male/Hair/hair-style-3-color-3.png";
import "assets/img/Avatar/Male/Hair/hair-style-3-color-4.png";
import "assets/img/Avatar/Male/Hair/hair-style-4-color-1.png";
import "assets/img/Avatar/Male/Hair/hair-style-4-color-2.png";
import "assets/img/Avatar/Male/Hair/hair-style-4-color-3.png";
import "assets/img/Avatar/Male/Hair/hair-style-4-color-4.png";

//Woman Hair//
import "assets/img/Avatar/Female/Hair/hair-style-1-color-1.png";
import "assets/img/Avatar/Female/Hair/hair-style-1-color-2.png";
import "assets/img/Avatar/Female/Hair/hair-style-1-color-3.png";
import "assets/img/Avatar/Female/Hair/hair-style-1-color-4.png";
import "assets/img/Avatar/Female/Hair/hair-style-2-color-1.png";
import "assets/img/Avatar/Female/Hair/hair-style-2-color-2.png";
import "assets/img/Avatar/Female/Hair/hair-style-2-color-3.png";
import "assets/img/Avatar/Female/Hair/hair-style-2-color-4.png";
import "assets/img/Avatar/Female/Hair/hair-style-3-color-1.png";
import "assets/img/Avatar/Female/Hair/hair-style-3-color-2.png";
import "assets/img/Avatar/Female/Hair/hair-style-3-color-3.png";
import "assets/img/Avatar/Female/Hair/hair-style-3-color-4.png";
import "assets/img/Avatar/Female/Hair/hair-style-4-color-1.png";
import "assets/img/Avatar/Female/Hair/hair-style-4-color-2.png";
import "assets/img/Avatar/Female/Hair/hair-style-4-color-3.png";
import "assets/img/Avatar/Female/Hair/hair-style-4-color-4.png";

//////////////////////////////////////////////////////////////////
//Man Suits//
import "assets/img/Avatar/Male/Clothes/clothes-man-coat.png";
import "assets/img/Avatar/Male/Clothes/clothes-man-shirt.png";
import "assets/img/Avatar/Male/Clothes/clothes-man-suit.png";
import "assets/img/Avatar/Male/Clothes/clothes-man-sweater.png";

//Woman Suits//
import "assets/img/Avatar/Female/Clothes/clothes-woman-coat.png";
import "assets/img/Avatar/Female/Clothes/clothes-woman-shirt.png";
import "assets/img/Avatar/Female/Clothes/clothes-woman-suit-black.png";
import "assets/img/Avatar/Female/Clothes/clothes-woman-suit-gray.png";

//////////////////////////////////////////////////////////////////
//Man Pants//
import "assets/img/Avatar/Male/Pants/pants-man-black.png";
import "assets/img/Avatar/Male/Pants/pants-man-jeans.png";
import "assets/img/Avatar/Male/Pants/pants-man-navy-blue.png";
import "assets/img/Avatar/Male/Pants/pants-man-caucasian.png";

//Woman Pants//
import "assets/img/Avatar/Female/Pants/dress-woman.png";
import "assets/img/Avatar/Female/Pants/pants-woman-beige.png";
import "assets/img/Avatar/Female/Pants/pants-woman-jeans.png";
import "assets/img/Avatar/Female/Pants/skirt-woman.png";

//////////////////////////////////////////////////////////////////
//Man & Woman Accessories//
import "assets/img/Avatar/Male/Accessories/accessories-man-cap.png";
import "assets/img/Avatar/Male/Accessories/accessories-man-glasses.png";
import "assets/img/Avatar/Male/Accessories/accessories-man-mask.png";
import "assets/img/Avatar/Male/Accessories/accessories-man-stethoscope.png";
import "assets/img/Avatar/Female/Accessories/accessories-woman-cap.png";
import "assets/img/Avatar/Female/Accessories/accessories-woman-glasses.png";
import "assets/img/Avatar/Female/Accessories/accessories-woman-mask.png";
import "assets/img/Avatar/Female/Accessories/accessories-woman-stethoscope.png";
//////////////////////////////////////////////////////////////////

//Background image//
import "assets/img/hemofarm-loby-background.png";
import "assets/img/hemofarm-building-background.png";
import "assets/img/hemofarm-login-background.webp";
import "assets/img/hall-background.png";
import "assets/img/screen-1.1.png";
import "assets/img/screen-1.2.png";
import "assets/img/screen-1.3.png";
import "assets/img/screen-2.1.png";
import "assets/img/screen-2.2.png";
import "assets/img/screen-2.3.png";
import "assets/img/screen-2.4.png";
import "assets/img/screen-2.5.png";
import "assets/img/screen-2.6.png";
import "assets/img/screen-3.png";
import "assets/img/screen-4.1.png";
import "assets/img/screen-4.2.png";
import "assets/img/screen-5.1.png";
import "assets/img/screen-5.2.png";
import "assets/img/screen-5.3.png";
import "assets/img/screen-5.4.png";
import "assets/img/screen-5.5.png";
import "assets/img/screen-5.6.png";
import "assets/img/screen-5.7.png";
import "assets/img/screen-5.8.png";

//Background image Mob//
import "assets/img/hemofarm-building-background-mob.png";
import "assets/img/hemofarm-building-background-sm.png";
import "assets/img/hemofarm-building-mobile-background-mob.png";
import "assets/img/hemofarm-login-background-mob.webp";
import "assets/img/hall-background-mob.png";
import "assets/img/arrow-right.svg";
import "assets/img/log-out.svg";
import "assets/img/screen-1.1-mob.svg";
import "assets/img/screen-2.1-mob.svg";
import "assets/img/screen-2.3-mob.svg";
import "assets/img/screen-2.4-mob.svg";
import "assets/img/screen-2.5-mob.svg";
import "assets/img/screen-2.6-mob.svg";
import "assets/img/screen-3-mob.svg";
import "assets/img/screen-4.1-mob.svg";
import "assets/img/screen-4.2-mob.svg";
import "assets/img/screen-4.3-mob.svg";
import "assets/img/screen-5.1-mob.svg";
import "assets/img/screen-5.4-mob.svg";
import "assets/img/screen-5.6-mob.svg";
import "assets/img/screen-5.8-mob.svg";
//////////////////////////////////////////////////////////////////

//Doors image//
import "assets/img/doors/marketing-door-closed.png";
import "assets/img/doors/marketing-door-open.png";
import "assets/img/doors/general-door-closed.png";
import "assets/img/doors/general-door-open.png";
import "assets/img/doors/marketing-door-closed.png";
import "assets/img/doors/technical-operations-door-closed.png";
import "assets/img/doors/technical-operations-door-open.png";
import "assets/img/doors/business-development-door-closed.png";
import "assets/img/doors/business-development-door-open.png";
import { baseURLLocal, baseURLProduction, baseURLStaging } from "../../../global-constants";

const baseUrl = baseURLProduction; // switch ENV

const setCurrentLevelLocal = (level) => {
  localStorage.setItem("currentLevel", level);
  localStorage.setItem("level", level);
};
function getPlayerName() {
  const namePlayer = JSON.parse(localStorage.getItem("user")).name;
  const userName = document.querySelector(".js-name-player");
  userName.textContent = namePlayer;
}
const redirects = {
  Intro: "question-intro",
  Doors: "doors-screen",
};

// check current user level 'Intro' or 'Doors'
export function checkUserLevel(introLevel) {
  if (introLevel) {
    // check if Intro completed
    if (introLevel.level === "Intro" && introLevel.progress === "completed") {
      setCurrentLevelLocal("Doors");
      return "Doors";
    }

    setCurrentLevelLocal("Intro");
    return "Intro";
  }
}

// check user status
export function checkUserStatus() {
  if (
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user"))
  ) {
    let currentLevel = checkUserLevel(
      JSON.parse(localStorage.getItem("Intro")) ?? null
    );

    localStorage.setItem("currentLevel", currentLevel);
    localStorage.setItem("level", currentLevel);

    if (JSON.parse(localStorage.getItem("user")).avatar_image) {
      let avatarCreatePlaceholder = document.querySelectorAll(
        ".js-avatar-placeholder"
      );
      avatarCreatePlaceholder.forEach((item) => {
        setAvatarPlaceholder(item);
      });
      localStorage.setItem(
        "avatarBase64",
        JSON.parse(localStorage.getItem("user")).avatar_image
      );

      if (
        JSON.parse(localStorage.getItem("Level 5")) &&
        JSON.parse(localStorage.getItem("Level 5")).progress === "completed"
      ) {
        localStorage.setItem("currentLevel", "Doors");
        localStorage.setItem("level", "Doors");
        // localStorage.setItem('currentQuestion', level4LocalStorage.question);
        localStorage.setItem("data-level-6", 6);
        let score = localStorage.getItem("currentScore");
        let resultEl = document.querySelectorAll(".js-result");

        resultEl.forEach((item) => {
          item.innerText = score;
        });
        goToScreen("doors-screen");
        // return;
      }

      if (
        JSON.parse(localStorage.getItem("Level 4")) &&
        JSON.parse(localStorage.getItem("Level 4")).progress === "completed"
      ) {
        localStorage.setItem("currentLevel", "Doors");
        localStorage.setItem("level", "Doors");
        // localStorage.setItem('currentQuestion', level4LocalStorage.question);
        localStorage.setItem("data-level-5", 5);
        let score = localStorage.getItem("currentScore");
        let resultEl = document.querySelectorAll(".js-result");

        resultEl.forEach((item) => {
          item.innerText = score;
        });
        goToScreen("doors-screen");
        // return;
      }

      if (
        JSON.parse(localStorage.getItem("Level 3")) &&
        JSON.parse(localStorage.getItem("Level 3")).progress === "completed"
      ) {
        localStorage.setItem("currentLevel", "Doors");
        localStorage.setItem("level", "Doors");
        // localStorage.setItem('currentQuestion', level4LocalStorage.question);
        localStorage.setItem("data-level-4", 4);
        let score = localStorage.getItem("currentScore");
        let resultEl = document.querySelectorAll(".js-result");

        resultEl.forEach((item) => {
          item.innerText = score;
        });
        goToScreen("doors-screen");
        // return;
      }

      if (
        JSON.parse(localStorage.getItem("Level 2")) &&
        JSON.parse(localStorage.getItem("Level 2")).progress === "completed"
      ) {
        localStorage.setItem("currentLevel", "Doors");
        localStorage.setItem("level", "Doors");
        // localStorage.setItem('currentQuestion', level3LocalStorage.question);
        localStorage.setItem("data-level-3", 3);
        let score = localStorage.getItem("currentScore");
        let resultEl = document.querySelectorAll(".js-result");

        resultEl.forEach((item) => {
          item.innerText = score;
        });
        goToScreen("doors-screen");
        // return;
      }

      if (
        JSON.parse(localStorage.getItem("Level 1")) &&
        JSON.parse(localStorage.getItem("Level 1")).progress === "completed"
      ) {
        localStorage.setItem("currentLevel", "Doors");
        localStorage.setItem("level", "Doors");
        // localStorage.setItem('currentQuestion', level2LocalStorage.question);
        localStorage.setItem("data-level-2", 2);
        let score = localStorage.getItem("currentScore");
        let resultEl = document.querySelectorAll(".js-result");

        resultEl.forEach((item) => {
          item.innerText = score;
        });
        goToScreen("doors-screen");
        // return;
      }

      if (
        JSON.parse(localStorage.getItem("Intro")) &&
        JSON.parse(localStorage.getItem("Intro")).progress === "completed"
      ) {
        localStorage.setItem("currentLevel", "Doors");
        localStorage.setItem("level", "Doors");
        // localStorage.setItem('currentQuestion', level1LocalStorage.question);
        let score = localStorage.getItem("currentScore");
        let resultEl = document.querySelectorAll(".js-result");

        resultEl.forEach((item) => {
          item.innerText = score;
        });
        goToScreen("doors-screen");
        return;
      }

      if (window.innerWidth < 768) {
        document
          .querySelector("#question-intro .avatar-placeholder")
          .classList.add("active");
      }
      goToScreen("question-intro");
      return;
    }

    if (!localStorage.getItem("avatarBase64")) {
      goToScreen("avatar-screen");
      return;
    }

    goToScreen(redirects[currentLevel]);
    return;
  }
  goToScreen("welcome");
}

checkUserStatus();

export function goToScreen(screenId) {
  let screens = document.querySelectorAll(".screen");
  screens.forEach((screen) => {
    screen.classList.remove("is-active");
  });
  document.getElementById(screenId).classList.add("is-active");
}

export function callApi(endpoint, type = "GET", data = {}, token = false) {
  const url = `${endpoint}`;

  const options = {
    method: type,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  };

  if (type !== "GET" && data) {
    options.body = JSON.stringify(data);
  }

  return fetch(url, options)
    .then((response) => response.json())
    .then((resp) => {
      // if (resp.errors && resp.errors.email) {
      //   if (resp.errors.email.length && resp.errors.email[0] === 'The email has already been taken.') {
      //     localStorage.setItem('guest-email', data.email);
      //     // alert('U bazi vec postoji korisnik sa ovim email-om');

      //     goToScreen('login-screen');
      //     // fill out email from localStorage
      //     document.getElementById('email-login').value = localStorage.getItem('guest-email') ?? '';
      //     return;
      //   }
      // }

      return resp;
    })
    .catch((error) => {
      return error;
    });
}

function setAvatarPlaceholder(selector) {
  setTimeout(() => {
    let getAvatarBase64 = localStorage.getItem("avatarBase64");

    // Create a new img element
    const imgElement = document.createElement("img");

    // Set its src attribute to the stored base64 image data
    imgElement.src = getAvatarBase64;

    // Clear the inner HTML of the selector
    selector.innerHTML = "";

    // Append the new img element to the selector
    selector.appendChild(imgElement);
  }, 300);
}

let btnCallApi = document.querySelector(".js-call-api");
let btnGoToAvatarScreen = document.querySelector(".js-go-to-avatar-screen");
let btnLogin = document.querySelector(".js-login");
let btnGoToDoorsScreen = document.querySelector(".js-go-to-doors-screen");
let btnLogout = document.querySelectorAll(".js-logout");
let btnFinishGame = document.querySelector(".js-finish-game");
let btnForgotPassword = document.querySelector(".js-forgot-password");
let btnResetPassword = document.querySelector(".js-reset-password");
let btnBackToLogin = document.querySelector(".js-back-to-login");
let btnBackToFirstScreen = document.querySelector(".js-back-to-first-screen");

function clearErrors() {
  document.getElementById("errors-email").innerHTML = "";
  document.getElementById("errors-name").innerHTML = "";
  document.getElementById("errors-email-login").innerHTML = "";
  document.getElementById("errors-password-login").innerHTML = "";
  document.getElementById("errors-reset-password-email").innerHTML = "";
}

// register
// @todo Redirect user to login page if email already exists in db
btnCallApi.addEventListener("click", async () => {
  btnCallApi.disabled = true;

  setTimeout(function () {
    btnCallApi.disabled = false;
  }, 2000);
  let response = await callApi(
    `${baseUrl}/register`,
    "POST",
    {
      name: document.getElementById("name").value,
      email: document.getElementById("email").value,
    },
    false
  );

  document
    .querySelectorAll(".js-close-already-registered-modal")
    .forEach((item) => {
      item.addEventListener("click", function () {
        document
          .querySelector(`[data-popup-modal="already-registered"]`)
          .classList.remove("is-active");

        document.querySelector(".overlay").classList.remove("is-active");
      });
    });

  document
    .querySelector(".js-redirect-to-login")
    .addEventListener("click", () => {
      document
        .querySelector(`[data-popup-modal="already-registered"]`)
        .classList.remove("is-active");

      document.querySelector(".overlay").classList.remove("is-active");

      goToScreen("login-screen");
    });

  clearErrors();

  // if email already exists in db, redirect user to login screen
  if (response && response.errors) {
    if (response.errors.email) {
      if (
        response.errors.email.length &&
        response.errors.email[0] === "Ova email adresa je već zauzeta."
      ) {
        document
          .querySelector(`[data-popup-modal="already-registered"]`)
          .classList.add("is-active");

        document.querySelector(".overlay").classList.add("is-active");

        localStorage.setItem(
          "guest-email",
          document.getElementById("email").value
        );
        // alert('U bazi vec postoji korisnik sa ovim email-om');

        // goToScreen('login-screen');
        // fill out email from localStorage
        document.getElementById("email-login").value =
          localStorage.getItem("guest-email") ?? "";
        return;
      }

      document
        .getElementById("errors-email")
        .insertAdjacentHTML("beforeend", response.errors.email[0]);
    }

    if (response.errors.name) {
      document
        .getElementById("errors-name")
        .insertAdjacentHTML("beforeend", response.errors.name[0]);
    }
  }

  if (response && response.user) {
    localStorage.setItem("guest-email", JSON.stringify(response.user.email));
    // redirect to auth screen
    goToScreen("email-auth");
  }
});

// login in flow with password only
btnGoToAvatarScreen.addEventListener("click", async () => {
  let response = await callApi(
    `${baseUrl}/login`,
    "POST",
    {
      email: JSON.parse(localStorage.getItem("guest-email")),
      password: document.getElementById("password").value,
    },
    false
  );

  if (response.errors) {
  }

  if (response && response.user) {
    localStorage.setItem("user", JSON.stringify(response.user));
    localStorage.setItem("access_token", response.access_token);
    getPlayerName();
    if (response.user.progress) {
      response.user.progress.forEach((progress) => {
        localStorage.setItem(progress.level, JSON.stringify(progress));
      });
    }

    // redirect to avatar screen
    goToScreen("avatar-screen");
  }
});

// login as a different screen
btnLogin.addEventListener("click", async () => {
  let response = await callApi(
    `${baseUrl}/login`,
    "POST",
    {
      email: document.getElementById("email-login").value,
      password: document.getElementById("password-login").value,
    },
    false
  );

  clearErrors();

  if (response && response.errors) {
    if (response.errors.email) {
      document
        .getElementById("errors-email-login")
        .insertAdjacentHTML("beforeend", response.errors.email[0]);
    }

    if (response.errors.password) {
      document
        .getElementById("errors-password-login")
        .insertAdjacentHTML("beforeend", response.errors.password[0]);
    }
  }

  if (response && response.user) {
    localStorage.setItem("user", JSON.stringify(response.user));
    localStorage.setItem("access_token", response.access_token);

    if (response.user.progress) {
      response.user.progress.forEach((progress) => {
        localStorage.setItem(progress.level, JSON.stringify(progress));
      });

      let totalScore = response.user.progress.reduce(
        (total, item) => item.score + total,
        0
      );

      localStorage.setItem("currentScore", totalScore);
      window.location.reload();
    }

    // redirect to avatar screen
    if (response.user.avatar_image) {
      goToScreen("question-intro");
      return;
    }
    goToScreen("avatar-screen");
  }
});

// forgot password screen
btnForgotPassword.addEventListener("click", async () => {
  document.getElementById('reset-password-email').value = localStorage.getItem('guest-email');
  goToScreen("forgot-password");
});

// reset password
btnResetPassword.addEventListener("click", async () => {
  clearErrors();
  let response = await callApi(
    `${baseUrl}/forgot-password-game`,
    "POST",
    {
      email: document.getElementById("reset-password-email").value,
    },
    false
  );

  if (response && response.errors) {
    if (response.errors.email) {
      document
        .getElementById("errors-reset-password-email")
        .insertAdjacentHTML("beforeend", response.errors.email[0]);
    }
  }

  if (response && response.status === "We have emailed your password reset link!") {
    goToScreen("forgot-password-confirm");
  }
});

// back to login after forgot password email
btnBackToLogin.addEventListener("click", () => {
  goToScreen("login-screen");
});

btnBackToFirstScreen.addEventListener("click", async () => {
  setTimeout(() => {
    goToScreen("login-screen");
  }, 400);
});

// logout

btnLogout.forEach((item) => {
  console.log(item);
  item.addEventListener("click", async () => {
    console.log("test");
    localStorage.clear();
    window.location.reload();
  });
});

// after intro is completed, go to doors screen
btnGoToDoorsScreen.addEventListener("click", async () => {
  let user = JSON.parse(localStorage.getItem("user"));
  // update db and set level to "Doors", and set localStorage currentLevel to "Doors"
  // let response = await callApi(`${baseUrl}/update-employee-progress`, 'POST', {
  //   question: 1,
  //   progress_name: 'Intro',
  //   progress: 'completed',
  //   score: localStorage.getItem('currentScore')
  // }, localStorage.getItem('access_token'));

  // if (response) {
  if (localStorage.getItem("quizCompleted_Intro")) {
    localStorage.setItem("currentLevel", "Doors");
    let introLevelLocalStorage = JSON.parse(localStorage.getItem("Intro"));
    introLevelLocalStorage.progress = "completed";
    localStorage.setItem("Intro", JSON.stringify(introLevelLocalStorage));
    localStorage.setItem("level", "Doors");
    localStorage.setItem("currentQuestion", 1);
    localStorage.setItem("currentQuestionAnswered", false);

    goToScreen("doors-screen");
  }
  //}
});

// finish game trigger rang list
// btnFinishGame.addEventListener("click", async () => {
//   async function generateRangList() {
//     let response = await callApi(
//       `${baseURLStaging}/rang-list`,
//       "GET",
//       {},
//       localStorage.getItem("access_token")
//     );
//     if (response.data) {
//       let rangList = document.querySelector("#finish-rang-list tbody");
//       rangList.innerHTML = "";
//       response.data.forEach((item, index) => {
//         let key = ++index;
//         rangList.insertAdjacentHTML(
//           "beforeend",
//           `<tr id="row-${item.id}">
//         <td>${key}.</td>
//         <td>${item.name}</td>
//         <td>${item.score}</td>
//       </tr>`
//         );

//         // set rang list position in quote
//         if (
//           localStorage.getItem("user") &&
//           item.id === JSON.parse(localStorage.getItem("user")).id
//         ) {
//           document.getElementById("rang-list-position").innerHTML = key;
//         }

//         if (
//           document.getElementById(
//             `row-${JSON.parse(localStorage.getItem("user")).id}`
//           )
//         ) {
//           document.getElementById(
//             `row-${JSON.parse(localStorage.getItem("user")).id}`
//           ).style.backgroundColor = "#fce6d2";
//           document.getElementById(
//             `row-${JSON.parse(localStorage.getItem("user")).id}`
//           ).style.borderRadius = "10px";
//           // document.getElementById(`row-${JSON.parse(localStorage.getItem('user')).id}`).style.margin = '10px';
//           // document.getElementById(`row-${JSON.parse(localStorage.getItem('user')).id}`).style.width = 'auto';
//           document.getElementById(
//             `row-${JSON.parse(localStorage.getItem("user")).id}`
//           ).style.overflow = "hidden";

//           function scrollToHalfHeightOfWrapper(wrapperId, targetElementId) {
//             const wrapper = document.querySelector(`#${wrapperId} tbody`);
//             const targetElement = document.getElementById(targetElementId);

//             if (!wrapper || !targetElement) {
//               console.error("Wrapper or target element not found!");
//               return;
//             }

//             // Calculate the half-height of the wrapper div
//             const halfHeight = wrapper.clientHeight / 2;

//             // Calculate the distance from the top of the wrapper to the target element
//             const distanceToTarget =
//               targetElement.offsetTop - wrapper.offsetTop;

//             // Calculate the scroll position to reach the target element at half the wrapper's height
//             const scrollPosition = distanceToTarget - halfHeight;

//             // Smoothly scroll the wrapper div to the calculated scroll position
//             wrapper.scrollTo({
//               top: scrollPosition,
//               behavior: "smooth",
//             });
//           }

//           // Call the function with the wrapper and target element IDs
//           scrollToHalfHeightOfWrapper(
//             "global-rang-list",
//             `row-${JSON.parse(localStorage.getItem("user")).id}`
//           );
//         }
//       });
//     }
//   }
//   generateRangList();
//   goToScreen("game-finish-screen");
// });

let currentScreen = 0; // initialize the current screen to 0
const totalScreens = document.querySelectorAll(".js-screen").length; // count the total number of screens
const buttonNext = document.querySelectorAll(".js-nextBtn");
// hide all screens except the first one
// changeScreen(currentScreen);

buttonNext.forEach((item) => {
  item.addEventListener("click", function () {
    if (currentScreen < totalScreens - 1) {
      currentScreen++; // increase the current screen by 1
      // changeScreen(currentScreen); // call the function to change the screen
    }
  });
});

// function to change the screen based on the current screen number
function changeScreen(screenNumber) {
  const screens = document.querySelectorAll(".js-screen");
  screens.forEach((screen, index) => {
    if (index === screenNumber) {
      screen.classList.add("is-active");
      screen.style.display = "block";
    } else {
      screen.classList.remove("is-active");
      screen.style.display = "none";
    }
  });
}

window.addEventListener("orientationchange", function () {
  if (window.orientation === 90 || window.orientation === -90) {
    document.querySelector(".lock-info").classList.add("is-active");
  } else {
    document.querySelector(".lock-info").classList.remove("is-active");
  }
});

window.addEventListener("resize", function () {
  var windowWidth = window.innerWidth;
  var infoDiv = document.querySelector(".js-tablet");

  if (windowWidth <= 1200) {
    infoDiv.classList.add("is-active");
  } else {
    infoDiv.classList.remove("is-active");
  }

  if (windowWidth <= 768) {
    infoDiv.classList.remove("is-active");
  }
});
