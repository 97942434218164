import { baseURLLocal, baseURLProduction, baseURLStaging } from "../../../global-constants";
import { callApi } from "../../../partials/pages/home";

export default class Popup {
  constructor() { }

  init() {
    const modalTriggers = document.querySelectorAll(".js-popup-trigger");
    const modalCloseTrigger = document.querySelectorAll(".js-close-modal");
    const bodyBlackout = document.querySelector(".overlay");
    const body = document.getElementsByTagName("html")[0];

    modalTriggers.forEach((trigger) => {
      trigger.addEventListener("click", () => {
        async function generateRangList() {
          let response = await callApi(
            `${baseURLProduction}/rang-list`,
            "GET",
            {},
            localStorage.getItem("access_token")
          );
          if (response.data) {
            let rangList = document.querySelector("#global-rang-list tbody");
            rangList.innerHTML = "";
            response.data.forEach((item, index) => {
              rangList.insertAdjacentHTML(
                "beforeend",
                `<tr id="row-${item.id}">
              <td>${++index}.</td>
              <td>${item.name}</td>
              <td>${item.score}</td>
            </tr>`
              );

              if (localStorage.getItem("user")) {
                if (
                  document.getElementById(
                    `row-${JSON.parse(localStorage.getItem("user")).id}`
                  )
                ) {
                  document.getElementById(
                    `row-${JSON.parse(localStorage.getItem("user")).id}`
                  ).style.backgroundColor = "#fce6d2";
                  document.getElementById(
                    `row-${JSON.parse(localStorage.getItem("user")).id}`
                  ).style.borderRadius = "10px";
                  // document.getElementById(`row-${JSON.parse(localStorage.getItem('user')).id}`).style.margin = '10px';
                  // document.getElementById(`row-${JSON.parse(localStorage.getItem('user')).id}`).style.width = 'auto';
                  document.getElementById(
                    `row-${JSON.parse(localStorage.getItem("user")).id}`
                  ).style.overflow = "hidden";

                  function scrollToHalfHeightOfWrapper(
                    wrapperId,
                    targetElementId
                  ) {
                    const wrapper = document.querySelector(
                      `#${wrapperId} tbody`
                    );
                    const targetElement =
                      document.getElementById(targetElementId);

                    if (!wrapper || !targetElement) {
                      console.error("Wrapper or target element not found!");
                      return;
                    }

                    // Calculate the half-height of the wrapper div
                    const halfHeight = wrapper.clientHeight / 2;

                    // Calculate the distance from the top of the wrapper to the target element
                    const distanceToTarget =
                      targetElement.offsetTop - wrapper.offsetTop;

                    // Calculate the scroll position to reach the target element at half the wrapper's height
                    const scrollPosition = distanceToTarget - halfHeight;

                    // Smoothly scroll the wrapper div to the calculated scroll position
                    wrapper.scrollTo({
                      top: scrollPosition,
                      behavior: "smooth",
                    });
                  }

                  // Call the function with the wrapper and target element IDs
                  scrollToHalfHeightOfWrapper(
                    "global-rang-list",
                    `row-${JSON.parse(localStorage.getItem("user")).id}`
                  );
                }
              }
            });
          }
        }
        generateRangList();

        const { popupTrigger } = trigger.dataset;

        const popupModal = document.querySelector(
          `[data-popup-modal="${popupTrigger}"]`
        );

        popupModal.classList.add("is-active");
        if (window.innerWidth < 560) {
          popupModal.scrollIntoView({ behavior: "smooth" });
        }
        if (bodyBlackout != null) {
          bodyBlackout.classList.add("is-active");
        }
        if (popupTrigger === "rules-1") {
          bodyBlackout.style.zIndex = "-1";
          setTimeout(() => {
            body.classList.remove("lock");
          }, 1000);
        }

        body.classList.add("lock");
        modalCloseTrigger.forEach((trigger) => {
          trigger.addEventListener("click", (e) => {
            e.preventDefault();
            popupModal.classList.remove("is-active");
            if (bodyBlackout != null) {
              bodyBlackout.classList.remove("is-active");
              bodyBlackout.style.zIndex = "";
            }
            body.classList.remove("lock");
          });
        });
        if (bodyBlackout != null) {
          bodyBlackout.addEventListener("click", () => {
            popupModal.classList.remove("is-active");
            bodyBlackout.classList.remove("is-active");
            bodyBlackout.style.zIndex = "";
            body.classList.remove("lock");
          });
        }
      });
    });
  }
}
